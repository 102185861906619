import React, { useState, useEffect } from 'react';

const Marquee = () => {
  // State to manage the custom message
  const [message, setMessage] = useState('');
  const [submittedMessage, setSubmittedMessage] = useState('');

  // Load the submitted message from localStorage when the component mounts
  useEffect(() => {
    const storedMessage = localStorage.getItem('userMessage');
    if (storedMessage) {
      setSubmittedMessage(storedMessage);
    }
  }, []);

  // Handle input change (updating the message in state)
  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  // Handle form submission
  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
    setSubmittedMessage(message); // Set the message to be displayed
    localStorage.setItem('userMessage', message); // Save the message to localStorage
    setMessage(''); // Clear the input field after submission
  };

  return (
    <div>
      {/* Form to input the custom message */}
      <h2>Submit Your Custom Message</h2>
      <form onSubmit={handleFormSubmit}>
        <div>
          <label htmlFor="messageInput">Your Message:</label>
          <textarea
            id="messageInput"
            value={message}
            onChange={handleInputChange}
            placeholder="Type your message here..."
            rows="4"
            cols="50"
            required
          />
        </div>
        <div>
          <button type="submit">Submit Message</button>
        </div>
      </form>

      {/* Display the submitted message */}
      {submittedMessage && (
        <div>
          <h3>Your Submitted Message:</h3>
          <p>{submittedMessage}</p>
        </div>
      )}

      {/* Marquee with the email link */}
      <p>
        <strong>Email:</strong>
        <a href="mailto:contact@metaormoney.io">
          contact@metaormoney.io
        </a>
      </p>
    </div>
  );
};

export default Marquee;
